import { useBoolean } from "ahooks"
import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd'
import React from "react"
import { MouseEvent, MouseEventHandler } from "react"

export interface ButtonProps extends Omit<AntButtonProps, 'onClick'> {
  onClick?: MouseEventHandler<HTMLElement> | ((e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => Promise<void>)
}

const Button = ({ ...props }: ButtonProps) => {
  const [loading, loadingActions] = useBoolean(false)
  
  const handleOnClick: MouseEventHandler<HTMLElement> = e => {
    const clicked = props.onClick?.(e)
    // @ts-ignore
    if (clicked?.then) {
      loadingActions.setTrue()
      clicked.finally(() => {
        loadingActions.setFalse()
      })
    }
  }

  return (
    <AntButton
      loading={loading}
      {...props}
      onClick={handleOnClick}
    />
  )
}

export default Button

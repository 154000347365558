import React from "react"
import { FloatButton as AntFloatButton, FloatButtonProps as AntFloatButtonProps } from 'antd'

export interface FloatButtonProps extends AntFloatButtonProps {
}

const FloatButton = ({ ...props }: FloatButtonProps) => {

  return (
    <AntFloatButton
      {...props}
    />
  )
}

export default FloatButton

import React from "react"
import { Avatar as AntAvatar, AvatarProps as AntAvatarProps } from 'antd'

export interface AvatarProps extends AntAvatarProps {
}

const Avatar = ({ ...props }: AvatarProps) => {

  return (
    <AntAvatar
      {...props}
    />
  )
}

export default Avatar

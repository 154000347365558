import React from "react"
import { Dropdown as AntDropdown, DropdownProps as AntDropdownProps, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons';

export interface DropdownProps extends AntDropdownProps {
  title: string
}

const Dropdown = ({ ...props }: DropdownProps) => {

  return (
    <AntDropdown
      {...props}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          {props.title}
          <DownOutlined />
        </Space>
      </a>
    </AntDropdown>
  )
}

export default Dropdown

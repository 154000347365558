import React from 'react'
import { Form as AntdForm, message } from 'antd'
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef } from 'react'
import Modal, { ModalRef, ModalProps } from '../modal'
import { CommonExtendFormProps } from './form'
import Form, { FormProps, FormType } from '.'
import { post, put, patch, destroy } from '@rails/request.js'
import { useToggle } from 'ahooks'
import classnames from 'classnames'

export type ModalFormRef = Partial<FormType & ModalRef>

export interface ModalFormProps extends Partial<CommonExtendFormProps>, Omit<FormProps, 'children'> {
  modalProps?: Omit<ModalProps, 'trigger'>
  submitPath?: string,
  submitMethod?: 'POST' | 'PUT' | 'PATCH' | 'DELETE',
}

const ModalForm: ForwardRefRenderFunction<ModalFormRef, ModalFormProps> = (
  {
    title,
    children,
    modalProps,
    onSubmit,
    onVisibleChange,
    okText = '确认',
    cancelText = '取消',
    submitPath,
    submitMethod,
    ...formProps
  },
  ref
) => {
  const [currentForm] = AntdForm.useForm()
  const formRef = useRef<FormType>(null)
  const modalRef = useRef<ModalRef>(null)
  const form = formProps.form ?? currentForm
  const [open, { toggle }] = useToggle(modalProps?.open || false)

  useImperativeHandle(
    ref,
    () => ({
      ...(formRef.current || {}),
      ...(modalRef.current || {}),
    }),
    []
  )

  const handleSubmit = async (values: any) => {
    if (!!submitPath && !!submitMethod) {
      const requestBody = {
        body: {...values},
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }

      let response: any

      switch (submitMethod) {
        case 'POST':
          response = await post(submitPath, requestBody)
          break
        case 'PUT':
          response = await put(submitPath, requestBody)
          break
        case 'PATCH':
          response = await patch(submitPath, requestBody)
          break
        case 'DELETE':
          response = await destroy(submitPath, requestBody)
          break
      }
      
      if (response.ok) {
        message.success('提交成功')
        form.resetFields()
      } else {
        message.error('提交失败')
      }
    } else if (!!onSubmit) {
      await onSubmit(values)
      form.resetFields()
    } else {
      console.log("submit error: no submitPath or onSubmit")
    }
  }

  const handleOk = async () => {
    await form.validateFields()
    const values = form.getFieldsValue()
    await handleSubmit(values)
    toggle()
  }

  const handleCancel = () => {
    toggle()
    modalProps?.onClose?.()
  }

  return (
    <Modal
      ref={modalRef}
      okText={okText}
      cancelText={cancelText}
      {...modalProps}
      open={open}
      onVisibleChange={onVisibleChange ?? modalProps?.onVisibleChange}
      onOk={handleOk}
      title={title}
      trigger={children}
      className={classnames({
        "modalForm": true,
        [modalProps?.className]: !!modalProps?.className,
      })}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form
        showActionButtons={false}
        // @ts-ignore
        ref={formRef}
        form={form}
        card={false}
        submitPath={submitPath}
        submitMethod={submitMethod}
        {...formProps}
      />
    </Modal>
  )
}

export default forwardRef(ModalForm)

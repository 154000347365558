import React from "react"
import { Timeline as AntTimeline, TimelineProps as AntTimelineProps } from 'antd'

export interface TimelineProps extends AntTimelineProps {
}

const Timeline = ({ ...props }: TimelineProps) => {

  return (
    <AntTimeline
      {...props}
    />
  )
}

export default Timeline

import React from "react"
import { Image as AntImage, ImageProps as AntImageProps } from 'antd'

export interface ImageProps extends AntImageProps {
}

const Image = ({ ...props }: ImageProps) => {

  return (
    <AntImage
      {...props}
    />
  )
}

export default Image

import React from "react"
import { Alert as AntAlert, AlertProps as AntAlertProps } from 'antd'

export interface AlertProps extends AntAlertProps {
  children: React.ReactNode
}

const Alert = ({ ...props }: AlertProps) => {

  return (
    <AntAlert
      {...props}
    >
      {props.children}
    </AntAlert>
  )
}

export default Alert

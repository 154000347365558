import React from 'react'
import { useRef, useLayoutEffect, FC } from 'react'

interface Props {
  html: string
}

const DangrousHTMLElement: FC<Props> = ({
  html
}) => {
	const elRef = useRef<HTMLDivElement>()

	useLayoutEffect(() => {
		const range = document.createRange()
		range.selectNode(elRef.current)
		const documentFragment = range.createContextualFragment(html)
        
		elRef.current.innerHTML = ''
		elRef.current.append(documentFragment)
	}, [])

  return (
    <div 
      ref={elRef} 
      dangerouslySetInnerHTML={{ __html: html }}
    >
    </div>
  )
}

export default DangrousHTMLElement
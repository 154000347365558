import React, { FC, useEffect, useState } from 'react'
import { Select } from 'antd'

export interface BaseSelectProps {
  name: string
  options?: Array<any>
  remoteUrl?: string
  defaultValue?: any
  wrapperClassName?: string
  className?: string
  mode?: any
  size?: any
  onChange?: (v) => void
}

const BaseSelect: FC<BaseSelectProps> = ({
  name,
  options,
  remoteUrl,
  defaultValue,
  wrapperClassName,
  className,
  mode,
  size,
  onChange,
}) => {
  const [selectOption, setSelectOption] = useState(null)
  const [dataOptions, setDataOptions] = useState([])

  const handleDataOptions = (items) => {
    const defaultOption = items.find((ele) => ele.value === defaultValue)
    if (defaultOption !== undefined) {
      setSelectOption({ value: defaultOption.value, label: defaultOption.label })
    }
    setDataOptions([...items])
  }

  useEffect(() => {
    if (!!options && options.length > 0) {
      handleDataOptions(options)
      return
    }

    if (!!remoteUrl) {
      fetch(remoteUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then((res) => res.json())
        .then((res) => {
          handleDataOptions(res.data.items)
          return
        })
    }

    return
  }, [options, remoteUrl])

  const handleOnChange = (v) => {
    setSelectOption(v)
    onChange?.(v.key)
  }

  const handleFilterOption = (input, option) => {
    return (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
  }

  return (
    <div className={wrapperClassName}>
      <input key={selectOption?.value} type="hidden" name={name} value={selectOption?.value} />
      <Select
        showSearch
        labelInValue
        className={className}
        style={{ width: '100%' }}
        defaultValue={defaultValue}
        placeholder='请选择'
        optionFilterProp="children"
        onChange={handleOnChange}
        mode={mode}
        filterOption={handleFilterOption}
        size={size}
        options={dataOptions}
      />
    </div>
  )
}

export default BaseSelect

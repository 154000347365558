import React from "react"
import { Descriptions as AntDescriptions, DescriptionsProps as AntDescriptionsProps } from 'antd'

export interface DescriptionsProps extends AntDescriptionsProps {
}

const Descriptions = ({ ...props }: DescriptionsProps) => {

  return (
    <AntDescriptions
      {...props}
    />
  )
}

export default Descriptions

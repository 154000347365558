import React, { FC } from "react"
import {  Form, Table, message } from "antd"
import type { ColumnsType } from 'antd/es/table'
import { useAntdTable, useTextSelection } from "ahooks"
import FilterBar from "./FilterBar"

interface QueryTableProps {
  rowSelection?: any
  columns: ColumnsType<any>
  remoteUrl: string
  filterBarOptions?: any[]
  onRowClick?: (record: any) => void
}

interface Result {
  list: any[]
  total: number,
}

const QueryTable: FC<QueryTableProps> = ({
  rowSelection,
  columns,
  remoteUrl,
  filterBarOptions,
  onRowClick
}) => {
  const [form] = Form.useForm()
  const { text } = useTextSelection()

  const getTableData = (
    { current, pageSize },
    formData: Object,
  ): Promise<Result> => {
    let query = `page=${current}&size=${pageSize}`
    Object.entries(formData).forEach(([key, value]) => {
      if (value) {
        query += `&${key}=${value}`;
      }
    })
    return fetch(`${remoteUrl}?${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 0) {
          return {
            total: res.total,
            list: res.data.items,
          }
        } else {
          res.message && message.error(res.message)
        }
      })
  }

  const { tableProps, search } = useAntdTable(getTableData, {
    defaultPageSize: 8,
    form,
  })

  const { submit, reset } = search

  return (
    <>
      <FilterBar
        filterBarOptions={filterBarOptions}
        submit={submit}
        reset={reset}
        form={form}
        showSearchButton={false}
        autoSearch={true}
      />
      <Table
        rowSelection={rowSelection}
        columns={columns}
        locale={{
          emptyText: '暂无数据'
        }}
        rowKey="id"
        className="whitespace-nowrap overflow-x-auto text-ellipsis"
        onRow={(record) => {
          return {
            onClick: (e) => {
              e.stopPropagation()
              onRowClick && text.length === 0 && onRowClick(record)
            },
          };
        }}
        {...tableProps}
      />
    </>
  )
}

export default QueryTable

import React, { useState, useEffect, FC } from "react"
import ImgCrop from 'antd-img-crop'
import { Upload, Button, Dropdown, Menu, message } from "antd"
import {
  CloseOutlined,
  UploadOutlined,
  ScissorOutlined,
  CaretDownOutlined,
} from "@ant-design/icons"
import {
  UploadChangeParam,
  UploadFile,
  UploadFileStatus,
} from "antd/lib/upload/interface"
import Modal from "antd/lib/modal/Modal"

interface CropImageUploaderProps {
  srcName?: string
  src?: string
  remote?: string
  label?: string
  name?: string
  width?: number
  height?: number
  value?: string
  defaultMode?: string
  onChange?: (url?: string) => void
  maxSize?: number
}

const CropImageUploader: FC<CropImageUploaderProps> = ({
  srcName,
  src,
  value,
  remote = "/api/v1/attachments/upload",
  label,
  name,
  width,
  height,
  onChange,
  defaultMode = "all",
  maxSize = null,
}) => {
  if (value) {
    src = value
  }

  const getFileUplod = (src: string, status: UploadFileStatus) => [
    {
      uid: "-1",
      name: "image.png",
      status: status,
      url: src,
      response: { url: src },
    },
  ]

  const [files, setFiles] = useState<UploadFile<any>[]>(
    src ? getFileUplod(src, "done") : []
  )
  const [directlyMode, setDirectlyMode] = useState(
    defaultMode === "directly"
  )
  const [visible, setVisible] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<any>()

  useEffect(() => {
    if (value) {
      src = value
    }
    if (src) {
      setFiles(src ? getFileUplod(src, "done") : [])
    }
  }, [src, value])

  const handeChange = (params: UploadChangeParam) => {
    const { file } = params
    if (file?.status == "removed") {
      onChange && onChange(null)
      setFiles([])
    } else {
      onChange && onChange(file?.response?.url)
      setFiles([file])
    }
  }
  const showModal = async (file: UploadFile) => {
    const previewContent = await getBase64(file)
    setPreviewImage(previewContent)
    setVisible(true)
  }

  const beforeUpload = (file) => {
    if (!maxSize) {
      return true
    }

    const inLimit = file.size / 1024 < maxSize
    if (!inLimit) {
      file.status = "removed"
      message.error(`Image must smaller than ${maxSize} KB!`)
    }

    return inLimit
  }

  const uploadButton = () => {
    const metaElement = document.querySelector(
      'meta[name="csrf-token"]'
    ) as HTMLMetaElement

    return (
      <Upload
        action={remote}
        fileList={files}
        onChange={handeChange}
        onPreview={showModal}
        listType="picture-card"
        name={name}
        maxCount={1}
        accept="image/*"
        headers={{
          "X-CSRF-Token": metaElement.content,
        }}
        multiple={false}
        beforeUpload={beforeUpload}>
        <div>
          {directlyMode && (
            <UploadOutlined style={{ fontSize: "24px", color: "#ccc" }} />
          )}
          {!directlyMode && (
            <ScissorOutlined style={{ fontSize: "24px", color: "#ccc" }} />
          )}

          <div className="mt-1">{label || `${width} * ${height}`}</div>
          {defaultMode === "all" && (
            <div className="mt-1 text-gray-400">
              <Dropdown
                placement="bottomCenter"
                arrow
                overlay={
                  <Menu
                    onClick={(info) => {
                      const e = info.domEvent
                      e.stopPropagation()
                      e.preventDefault()

                      if (info.key == "0") {
                        setDirectlyMode(false)
                      } else {
                        setDirectlyMode(true)
                      }

                      return false
                    }}
                    defaultSelectedKeys={directlyMode ? ["1"] : ["0"]}>
                    <Menu.Item key="0" icon={<ScissorOutlined />}>
                      图片裁剪
                    </Menu.Item>
                    <Menu.Item key="1" icon={<UploadOutlined />}>
                      普通上传
                    </Menu.Item>
                  </Menu>
                }>
                <span className="text-gray-400">
                  {!directlyMode ? "图片裁剪" : "普通上传"}
                  <CaretDownOutlined />
                </span>
              </Dropdown>
            </div>
          )}
        </div>
      </Upload>
    )
  }

  return (
    <div className="crop-image-uploader">
      <div style={{ border: "1px solid #ddd;", padding: "5px" }}>
        <div>
          {!directlyMode && (
            <ImgCrop
              quality={1}
              modalTitle="图片裁剪"
              zoom
              aspect={width / height}
              rotate
              grid={true}
              beforeCrop={(file: File) => !file.name.endsWith(".gif")}>
              {uploadButton()}
            </ImgCrop>
          )}
          {directlyMode && uploadButton()}
        </div>
      </div>
      {files.length > 0 && (
        <input hidden name={srcName} value={files[0]?.response?.url} />
      )}
      <Modal
        visible={visible}
        footer={null}
        width={"80%"}
        onCancel={() => setVisible(false)}
        closeIcon={
          <Button icon={<CloseOutlined />} shape="circle" size="small" />
        }
        bodyStyle={{ padding: 0 }}>
        <img src={previewImage} style={{ width: "100%" }} />
      </Modal>
    </div>
  )
}

export default CropImageUploader

const getBase64 = (file) => {
  if (!file.response?.url) {
    return
  }
  if (file.uid === "-1") {
    return new Promise((resolve, reject) => {
      fetch(file.response?.url)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onload = () => resolve(reader.result)
          reader.onerror = (error) => reject(error)
        })
    })
  } else {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file.originFileObj)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }
}

export const registerComponentProperty = <T extends Function, O extends object>(
  component: T,
  originComponent: object
) => {
  for (let i in originComponent) {
    if (!component.hasOwnProperty(i) && i !== 'defaultProps') {
      component[i] = originComponent[i]
    }
  }
  return component as T & O
}
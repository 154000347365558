import { Modal as AntModal, ModalProps as AntModalProps, ConfigProvider } from 'antd'
import { forwardRef, Ref, ReactNode, useRef } from 'react'
import useTriggerOpen, { OpenRef, TriggerProps } from '../hooks/useTriggerOpen'
import React from 'react'
import { registerComponentProperty } from '../util/util'

export type ModalRef = OpenRef

export interface ModalProps extends Omit<AntModalProps, 'onClose' | 'onCancel' | 'onOk'>, TriggerProps {
  subtitle?: ReactNode
}

const Modal = ({ children, wrapClassName, title, subtitle, ...props }: ModalProps, ref: Ref<ModalRef>) => {
  const { el, onClose, ...triggerProps } = useTriggerOpen(ref, props)
  const DomRef = useRef<HTMLDivElement>(null)
  const getPopupContainer = () => {
    return DomRef?.current ?? document.body
  }

  return (
    <>
      {el}
      <AntModal
        {...props}
        {...triggerProps}
        title={
          subtitle ? (
            <div>
              <div>{title}</div>
              <div className="text-sm font-normal">{subtitle}</div>
            </div>
          ) : (
            title
          )
        }
        onCancel={onClose}
      >
        <div ref={DomRef}>
          <ConfigProvider getPopupContainer={getPopupContainer}>{children}</ConfigProvider>
        </div>
      </AntModal>
    </>
  )
}

const forwardModal = forwardRef(Modal)

export default registerComponentProperty<typeof forwardModal, typeof AntModal>(forwardModal, AntModal)

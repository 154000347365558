import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { Button, Modal, Spin, message } from "antd"
import { ButtonType } from "antd/lib/button"
import { useBoolean, useToggle } from 'ahooks'
import type { DraggableData, DraggableEvent } from 'react-draggable'
import Draggable from 'react-draggable'
import { BorderInnerOutlined } from "@ant-design/icons"
import DangrousHTMLElement from "./DangrousHTMLElement"

interface Props {
  buttonType?: ButtonType
  buttonText?: string
  buttonClassName?: string
  remoteUrl: string
  width?: number
}

const RemoteModal: FC<Props> = ({
  buttonType = 'link',
  buttonText = '点击打开',
  buttonClassName,
  remoteUrl,
  width,
}) => {
  const [open, { toggle }] = useToggle()
  const [loading, { setTrue: setLoadingTrue, setFalse: setLoadingFalse }] = useBoolean(false)
  const [html, setHtml] = useState("")
  const [draggableDisabled, { setTrue: setDraggableDisabledTrue, setFalse: setDraggableDisabledFalse }] = useBoolean(false)
  const [draggableBounds, setDraggableBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const draggleRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!remoteUrl || !open) return
    setLoadingTrue()

    fetch(remoteUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/html',
        'Accept': 'text/html'
      }
    })
      .then((res) => {
        console.log("获取远程内容成功", res)

        if (!res.ok) {
          message.error('获取远程内容失败，请联系管理员')
        }

        return res.text()
      })
      .then(data => {
        console.log("获取远程内容成功", data)
        setHtml(data)
      })
      .catch(err => {
        console.log("获取远程内容失败", err)
      })
      setLoadingFalse()
  }, [remoteUrl, open])

  const renderHTML = useMemo(() => {
    if (loading || !html) return (
      <div className="w-full h-full flex justify-center items-center">
        <Spin />
      </div>
    )

    return ( <DangrousHTMLElement html={html} /> )
  }, [html, loading])

  const onDraggableStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (!targetRect) {
      return
    }
    setDraggableBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    })
  }

  return (
    <>
      <Button style={{height: '20px'}} type={buttonType} onClick={toggle} size="small" className={buttonClassName}>
        {buttonText}
      </Button>
      <Modal
        open={open}
        footer={null}
        onCancel={toggle}
        width={width}
        maskClosable={false}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
              color: '#7d7a7a',
            }}
            className="flex items-center text-base"
            onMouseOver={() => {
              if (draggableDisabled) {
                setDraggableDisabledFalse()
              }
            }}
            onMouseOut={() => {
              setDraggableDisabledTrue()
            }}
          >
            <span className="mr-1"><BorderInnerOutlined /></span>
            <span>长按拖动</span>
          </div>
        }
        modalRender={(modal) => (
          <Draggable
            disabled={draggableDisabled}
            bounds={draggableBounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onDraggableStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        destroyOnClose
      >
        {renderHTML}
      </Modal>
    </>
  )
}

export default RemoteModal

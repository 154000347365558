import React, { FC, useMemo, useState } from "react"
import { Button, Modal } from "antd"
import { ButtonType } from "antd/lib/button"
import { useToggle } from 'ahooks'
import QueryTable from "components/common/queryTable/QueryTable"
import { string } from "prop-types"

interface Props {
  showDomId?: string
  buttonType?: ButtonType
  buttonText?: string
  columns: any[]
  filterBarOptions?: any[]
  remoteUrl: string
  enableRowClick?: boolean
}

const DefaultPostingModal: FC<Props> = ({
  showDomId,
  buttonType = "link",
  buttonText = "点击打开",
  columns,
  filterBarOptions,
  remoteUrl,
  enableRowClick = false
}) => {
  const [open, { toggle }] = useToggle()
  const [selectedRows, setSelectedRows] = useState<any[]>([])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows)
    },
  }

  const handleOk = () => {
    renderShowDom(selectedRows[0]?.fd)
    toggle()
  }

  const renderShowDom = (text: string) => {
    if (!!showDomId && !!text) {
      const showDom = document.getElementById(showDomId)
      if (showDom) {
        showDom.innerHTML = text
      }
    }
  }

  const renderButton = useMemo(() => {
    if (buttonType === "link") {
      return (
        <a onClick={toggle}>
          {buttonText}
        </a>
      )
    } else {
      return (
        <Button type={buttonType} onClick={toggle}>
          {buttonText}
        </Button>
      )
    }
  }, [buttonType])

  const onRowClick = (record: any) => {
    if (!enableRowClick) return false

    setSelectedRows(...[record])
    renderShowDom(record.fd)
    handleOk()
  }

  return (
    <>
      {renderButton}
      <Modal
        open={open}
        onCancel={toggle}
        cancelText="取消"
        okText="确认"
        onOk={handleOk}
        width={800}
        className="h-4/5 overflow-y-auto"
        cancelButtonProps={{
          className: "text-xs"
        }}
        okButtonProps={{
          className: "text-xs text-white"
        }}
      >
        <QueryTable
          rowSelection={{
            type: "radio",
            ...rowSelection
          }}
          columns={columns}
          filterBarOptions={filterBarOptions}
          remoteUrl={remoteUrl}
          onRowClick={onRowClick}
        />
      </Modal>
    </>
  )
}

export default DefaultPostingModal

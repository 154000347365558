import React, { FC, useEffect, useState } from 'react'
import BaseSelect, { BaseSelectProps } from 'components/common/form/fields/BaseSelect'

interface Props extends BaseSelectProps {
  dataSource?: Array<any>
  replaceDomMap?: any
  optionValue?: string
  optionLabels?: Array<string>
  inputValueReplaceDomMap?: any
}

const ProjectSelect: FC<Props> = ({
  name,
  dataSource,
  remoteUrl,
  defaultValue,
  wrapperClassName,
  className,
  mode,
  size,
  optionValue = 'id',
  optionLabels = ['fd'],
  replaceDomMap,
  inputValueReplaceDomMap
}) => {
  const [data, setData] = useState(dataSource)
  const [dataOptions, setDataOptions] = useState([])

  const handleDataOptions = opts => {
    const result = opts.map((i) => {
      return {
        value: i[optionValue],
        label: optionLabels.map((x) => i[x]).join(' - ')
      }
    })

    setDataOptions([...result])
  }

  useEffect(() => {
    if (!!dataSource && dataSource.length > 0) {
      handleDataOptions(dataSource)
      return
    }

    if (!!remoteUrl) {
      fetch(remoteUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then((res) => res.json())
        .then((res) => {
          setData([...res.data.items])
          handleDataOptions(res.data.items)
          return
        })
    }

    return
  }, [dataSource, remoteUrl])

  const renderShowDom = (domId: string, text: string) => {
    if (!domId || !text) return

    const dom = document.getElementById(domId)
    if (dom) {
      dom.innerHTML = text
    }
  }

  const renderInputValueDom = (domId: string, value: string) => {
    if (!domId || !value) return
    
    const dom = document.getElementById(domId)
    if (dom) {
      // @ts-ignore
      dom.value = value
      dom.setAttribute('value', value)
    }
  }

  const handleSelectChange = val => {
    if (!data) return null

    const selectData = data.find((i) => i[optionValue] === val)
    if (!!replaceDomMap) {
      for (const [key, value] of Object.entries(replaceDomMap)) {
        if (!!value) {
          renderShowDom(key, selectData[value as string])
        }
      }
    }

    if (!!inputValueReplaceDomMap) {
      for (const [key, value] of Object.entries(inputValueReplaceDomMap)) {
        if (!!value) {
          renderInputValueDom(key, selectData[value as string])
        }
      }
    }
  }

  return (
    <BaseSelect
      name={name}
      options={dataOptions}
      defaultValue={defaultValue}
      wrapperClassName={wrapperClassName}
      className={className}
      mode={mode}
      size={size}
      onChange={handleSelectChange}
    />
  )
}

export default ProjectSelect

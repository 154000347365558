import React from "react"
import { Empty as AntEmpty, EmptyProps as AntEmptyProps } from 'antd'

export interface EmptyProps extends AntEmptyProps {
}

const Empty = ({ ...props }: EmptyProps) => {

  return (
    <AntEmpty
      {...props}
    />
  )
}

export default Empty

import React from "react"
import { Result as AntResult, ResultProps as AntResultProps } from 'antd'

export interface ResultProps extends AntResultProps {
  children: React.ReactNode
}

const Result = ({ ...props }: ResultProps) => {

  return (
    <AntResult
      {...props}
    >
      {props.children}
    </AntResult>
  )
}

export default Result

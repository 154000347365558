import React from "react"
import { QRCode as AntQRCode, QRCodeProps as AntQRCodeProps } from 'antd'

export interface QRCodeProps extends AntQRCodeProps {
}

const QRCode = ({ ...props }: QRCodeProps) => {

  return (
    <AntQRCode
      {...props}
    />
  )
}

export default QRCode

// import Rails from "@rails/ujs"

// Rails.start()

const componentRequireContext = require.context("components", true)
const ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

ReactRailsUJS.handleUnmount()
ReactRailsUJS.handleEvent("turbo:load", ReactRailsUJS.handleMount)
ReactRailsUJS.handleEvent(
  "turbo:before-render",
  ReactRailsUJS.handleUnmount
)

require("stylesheets/index")

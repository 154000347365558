import React, { FC, useMemo } from "react"
import { StarOutlined, StarFilled, StarTwoTone } from '@ant-design/icons';

interface Props {
  name: string
}

const Icon: FC<Props> = ({
  name,
}) => {

  const renderIcon = useMemo(() => {
    if (!name) return null

    switch (name) {
      case 'star':
        return (<StarOutlined />)
      case 'star-filled':
        return (<StarFilled />)
      case 'star-two-tone':
        return (<StarTwoTone />)
      default:
        return null
    }
  }, [name])
  
  return renderIcon
}

export default Icon

import React from "react"
import { Popover as AntPopover, PopoverProps as AntPopoverProps } from 'antd'

export interface PopoverProps extends AntPopoverProps {
}

const Popover = ({ ...props }: PopoverProps) => {

  return (
    <AntPopover
      {...props}
    />
  )
}

export default Popover

import * as React from "react"
import { Result } from "antd"

interface Props {
  title: string
}

function Home(props: Props): JSX.Element {
  const { title } = props
  return (
    <>
      <Result
        status="403"
        title={title}
      />
    </>
  )
}

export default Home

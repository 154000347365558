import React from "react"
import { Card as AntCard, CardProps as AntCardProps } from 'antd'

export interface CardProps extends AntCardProps {
}

const Card = ({ ...props }: CardProps) => {

  return (
    <AntCard
      {...props}
    />
  )
}

export default Card

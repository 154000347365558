import React, { FC, useMemo } from "react"
import { Button, Col, Form, Input, Row, Select } from "antd"
const { Option } = Select;

interface Props {
  filterBarOptions?: any[]
  submit?: () => void
  reset?: () => void
  form?: any
  showSearchButton?: boolean
  autoSearch?: boolean
}

const FilterBar: FC<Props> = ({
  filterBarOptions,
  submit,
  reset,
  form,
  showSearchButton = true,
  autoSearch = false,
}) => {
  const advanceSearchForm = useMemo(() => {
    if (!filterBarOptions) return null

    return (
      <div className="my-2">
        <Form
          form={form}
          layout="inline"
          style={{ maxWidth: 'none' }}
          onValuesChange={(changedValues, allValues) => {
            if (!!changedValues && autoSearch) {
              submit && submit()
            }
          }}
        >
          {filterBarOptions.map((item, index) => {
            switch (item.type) {
              case 'input':
                return (
                  <Form.Item name={item.name}>
                    <Input placeholder={`请输入${item.label}`} />
                  </Form.Item>
                )
              case 'select':
                return (
                  <Form.Item name={item.name}>
                    <Select placeholder={`请选择${item.label}`}>
                      {item.options.map((option, index) => (
                        <Option value={option.value} key={index}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )
              default:
                return null
            }
          })}
          {showSearchButton && (
            <div className="space-x-2">
            <Button
              type="primary"
              onClick={submit}
              size="small"
            >
              <span className="text-xs text-white">搜索</span>
            </Button>
            <Button
              onClick={reset}
              size="small"
            >
              <span className="text-xs">清除搜索</span>
            </Button>
            </div>
          )}
        </Form>
      </div>
    )
  }, [filterBarOptions])

  return advanceSearchForm
}

export default FilterBar

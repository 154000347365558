import { useBoolean } from 'ahooks'
import { Card, Form as AntForm, FormProps as AntFormProps, Skeleton, Button } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import React, { useEffect, useImperativeHandle } from 'react'
import { registerComponentProperty } from '../util/util'
import { post, put, patch, destroy } from '@rails/request.js'
import FormItem from './FormItem'

declare type RecursivePartial<T> = T extends object
  ? {
      [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
        ? RecursivePartial<T[P]>
        : T[P]
    }
  : any

export type FormType<Values = any> = {
  form: FormInstance<Values>
  onSubmit: () => void
  resetFields: () => void
  setFieldsValue: FormInstance<Values>['setFieldsValue']
  getFieldsValue: FormInstance<Values>['getFieldsValue']
}

export interface FormProps<Values = any> extends AntFormProps<Values> {
  data?: RecursivePartial<Values>
  formData?: any[]
  submit?:
    | ((values: Values) => any)
    | ((values: Values) => Promise<void>)
  cancel?: () => void
  title?: string
  card?: boolean
  showActionButtons?: boolean
  loading?: boolean
  wrapperClassNames?: string
  submitPath?: string,
  submitMethod?: 'POST' | 'PUT' | 'PATCH' | 'DELETE',
}

const Form = <Values = any,>(
  {
    data,
    formData = [],
    submit,
    cancel,
    title,
    card = true,
    showActionButtons = true,
    loading = false,
    wrapperClassNames,
    submitPath,
    submitMethod,
    ...formProps
  }: FormProps<any>,
  ref: React.Ref<FormType<Values>> | null | undefined
) => {
  const form = formProps.form ?? AntForm.useForm()[0]
  
  const [submitLoading, submitLoadingActions] = useBoolean(false)

  useImperativeHandle(ref, () => ({
    form,
    onSubmit: () => {
      form?.submit()
    },
    resetFields: () => {
      form?.resetFields()
    },
    setFieldsValue: values => {
      form?.setFieldsValue(values)
    },
    getFieldsValue: () => form?.getFieldsValue(),
  }))

  const onFinishFailed: FormProps<Values>['onFinishFailed'] = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    if (data && Object.keys(data).length) {
      form.setFieldsValue(data)
    } else {
      form.resetFields()
    }
  }, [data])

  const onFinish = async (values: Values) => {
    try {
      submitLoadingActions.setTrue()
      //@ts-ignore
      if (!!submitPath && !!submitMethod) {
        const requestBody = {
          body: {...values},
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
    
        switch (submitMethod) {
          case 'POST':
            await post(submitPath, requestBody)
            break
          case 'PUT':
            await put(submitPath, requestBody)
            break
          case 'PATCH':
            await patch(submitPath, requestBody)
            break
          case 'DELETE':
            await destroy(submitPath, requestBody)
            break
        }
      } else if (!!submit) {
        await submit?.(values)
      } else {
        console.log("submit error: no submitPath or onSubmit")
      }
    } catch (e) {
      console.log(e)
    } finally {
      submitLoadingActions.setFalse()
    }
  }

  return (
    <Card
      bordered={false}
      className={card ? 'mb-5' : 'ant-card-none'} title={title}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <div className={wrapperClassNames}>
          <AntForm<Values>
            layout="vertical"
            {...formProps}
            form={form}
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="antdForm"
          >
            {formData.map((item, index) => {
              return (
                <FormItem item={item} index={index}  />
              )
            })}
            {showActionButtons && (
              <AntForm.Item key="submit">
                <div className="flex space-x-3">
                  <Button type="primary" htmlType="submit" loading={submitLoading} key="submit">
                    提交
                  </Button>
                  <Button
                    key="cancel"
                    onClick={() => {
                      cancel ? cancel() : window.history.back()
                    }}
                    disabled={submitLoading}
                  >
                    取消
                  </Button>
                </div>
              </AntForm.Item>
            )}
          </AntForm>
        </div>
      )}
    </Card>
  )
}

const forwardFrom = React.forwardRef(Form)

export default registerComponentProperty<typeof forwardFrom, typeof AntForm>(forwardFrom, AntForm)

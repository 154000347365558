import { useBoolean } from 'ahooks'
import {
  useMemo,
  useCallback,
  MouseEvent,
  KeyboardEvent,
  useImperativeHandle,
  Ref,
  cloneElement,
  ReactElement,
  useEffect,
} from 'react'

export type OpenRef = {
  onClose: (e?: MouseEvent | KeyboardEvent) => void
}

export interface TriggerProps {
  open?: boolean
  visible?: boolean
  onCancel?: (e?: MouseEvent | KeyboardEvent) => void
  onClose?: (e?: MouseEvent | KeyboardEvent) => void
  trigger?: ReactElement
  onVisibleChange?: (bool: boolean) => void
  onOk?: ((e?: MouseEvent<HTMLElement>) => void) | ((e?: MouseEvent<HTMLElement>) => Promise<void>)
  confirmLoading?: boolean
}

const useTriggerOpen = (ref: Ref<OpenRef>, props: TriggerProps) => {
  const [open, openActions] = useBoolean(false)
  const [loading, loadingActions] = useBoolean(false)

  const usedOpen = useMemo(() => props.open ?? props.visible ?? open, [open, props.open, props.visible])

  const handleClose = useCallback(
    (e?: MouseEvent | KeyboardEvent) => {
      props.onCancel?.(e)
      props.onClose?.(e)
      openActions.setFalse()
    },
    [props.onClose, props.onCancel]
  )

  useImperativeHandle(
    ref,
    () => ({
      onClose: handleClose,
    }),
    [handleClose]
  )

  const triggerEl = useMemo(
    () =>
      props.trigger &&
      cloneElement(props.trigger, {
        onClick(e: MouseEvent) {
          try {
            e.stopPropagation()
          } catch (e) {
            console.error(e)
          }
          openActions.setTrue()
        },
      }),
    [props.trigger]
  )

  useEffect(() => {
    props.onVisibleChange?.(usedOpen)
  }, [usedOpen])

  const handleOk = useCallback(() => {
    const onOkClicked = props.onOk?.()
    // @ts-ignore
    if (onOkClicked?.then) {
      loadingActions.setTrue()
      onOkClicked?.then(() => {
        handleClose()
      })
      onOkClicked?.finally(() => {
        loadingActions.setFalse()
      })
    } else {
      handleClose()
    }
  }, [props.onOk, handleClose])

  return useMemo(
    () => ({
      el: triggerEl,
      open: usedOpen,
      onOk: handleOk,
      onClose: handleClose,
      confirmLoading: props.confirmLoading ?? loading,
    }),
    [usedOpen, handleClose, triggerEl, handleOk, loading, props.confirmLoading]
  )
}

export default useTriggerOpen

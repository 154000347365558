import React from "react"
import { Tag as AntTag, TagProps as AntTagProps } from 'antd'

export interface TagProps extends AntTagProps {
}

const Tag = ({ ...props }: TagProps) => {

  return (
    <AntTag
      {...props}
    />
  )
}

export default Tag

import React, { FC, useState } from "react"
import { toPng } from 'html-to-image'
import { Button, Modal, message } from "antd"
import { ButtonType } from "antd/lib/button"
import { post } from '@rails/request.js'
import { useBoolean, useToggle } from 'ahooks'

interface Props {
  domId: string
  buttonType?: ButtonType
  buttonText?: string
  backgroundColor?: string
}

const HtmlToImage: FC<Props> = ({
  domId,
  buttonType = "primary",
  buttonText = "生成图片",
  backgroundColor = "#ffffff",
}) => {
  const [open, { toggle }] = useToggle()
  const [image, setImage] = useState("")
  const [loading, setLoading ] = useBoolean(false)
  var node = document.getElementById(domId)
  if (!node) return null

  const width = node.offsetWidth + 20
  const height = node.offsetHeight + 20

  const generate = () => {
    setLoading.setTrue()
    // @ts-ignore
    toPng(node, { width: width, height: height, pixelRatio: 1, backgroundColor: backgroundColor })
      .then(async function (dataUrl) {
        setImage(dataUrl)
        toggle()
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      })
      .finally(function () {
        setLoading.setFalse()
      })
  }

  const upload = async () => {
    const response = await post(`/api/v1/attachments/upload`, {
      body: {
        file: image,
        type: 'base64',
        file_type: 'image',
      },
    })
    if (response.ok) {
      message.success("上传成功")
      toggle()
    } else {
      message.error("上传失败")
    }
  }

  return (
    <>
      <Button
        type={buttonType}
        onClick={generate}
        loading={loading}
        className="m-2"
      >
        {buttonText}
      </Button>
      <Modal
        open={open}
        onCancel={toggle}
        cancelText="取消"
        okText="上传"
        onOk={upload}
      >
        {!!image && (
          <img src={image} />
        )}
      </Modal>
    </>
  )
}

export default HtmlToImage
